<template>
  <!-- <section> -->
  <b-card no-body>
    <b-card-body>
      <b-card-title>
        <b-row>
          <b-col>
            <div>Dibatalkan</div>
          </b-col>
        </b-row>
      </b-card-title>
      <b-card-text class="blog-content-truncate">
        <b-row>
          <b-col md="3" sm="4" class="my-1">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
            </b-form-group>
          </b-col>
          <b-col md="4" sm="8" class="my-1">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Jenis</label>
              <b-form-select v-model="filterType" size="sm" :options="optType" class="w-50">
                <template v-slot:first>
                  <option :value="null">-- Pilih --</option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="5" class="my-1">
            <b-form-group label-for="filterInput" class="mb-0">
              <b-input-group size="sm">
                <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Search......" />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              :fields="fields"
              :items="data"
            >
              <!-- A virtual column -->
              <template #cell(merchant_ref)="data">
                <b @click="detail(data)"> {{ data.value }} </b>
              </template>

              <template #cell(type)="data">
                <b-badge variant="primary" v-if="data.value == 'course'"> Paket </b-badge>
                <b-badge variant="info" v-else-if="data.value == 'snbp'"> SNBP </b-badge>
                <b-badge variant="success" v-else> Ebook </b-badge>
              </template>

              <template #cell(status)="data">
                <b-badge pill variant="success" v-if="data.value == 'PAID'"> Terbayar </b-badge>
                <b-badge pill variant="info" v-if="data.value == 'UNPAID'"> Menunggu </b-badge>
                <b-badge pill variant="danger" v-if="data.value == 'FAILED' || data.value == 'CANCELED'"> Dibatalkan </b-badge>
                <b-badge pill variant="warning" v-if="data.value == 'EXPIRED'"> Telat Bayar </b-badge>
                <b-badge pill variant="light-success" v-if="data.value == 'REFUND'"> Pengembalian </b-badge>
              </template>

              <template #cell(amount)="data">
                {{ "Rp." + formatRupiah(data.value) }}
              </template>

              <template #cell(created_at)="data">
                {{ humanDate(data.value) }}
              </template>

              <template #cell(unduh)="data">
                <b-button size="sm" variant="outline-dark" v-if="data.item.status == 'PAID'"><feather-icon icon="DownloadIcon" /></b-button>
              </template>
            </b-table>
            <b-pagination v-model="currentPage" :total-rows="data.length" :per-page="perPage" align="center" size="sm" class="my-0" />
          </b-col>
        </b-row>
      </b-card-text>
    </b-card-body>
  </b-card>
</template>

<script>
import { BRow, BCol, BTable, BCard, BCardText, BCardTitle, BCardBody, BPagination, BProgress, BBadge, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormSelect, BInputGroup, BInputGroupAppend, VBTooltip } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    VBTooltip,
    ToastificationContent,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  props: ["data"],
  data() {
    return {
      fields: [
        { key: "merchant_ref", label: "Invoice", sortable: true },
        { key: "type", label: "Jenis ", sortable: true },
        { key: "order_items.name", label: "Nama ", sortable: true },
        { key: "amount", label: "Harga", sortable: true },
        { key: "created_at", label: "Tanggal" },
        { key: "status", label: "Status Pembayaran", sortable: true },
        { key: "unduh", label: "" },
      ],

      total: [],
      record: [],
      optFilter: [
        { id: "question", value: "Pertanyaan" },
        { id: "answer", value: "Jawaban" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      filterType: null,
      optType: [
        { text: "Paket", value: "course" },
        { text: "Ebook", value: "ebook" },
        { text: "SNBP", value: "snbp" },
      ],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      search: null,
      idd: null,
    };
  },
  watch: {
    filterType(val) {
      this.$emit("load", val);
    },
  },
  computed: {},
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    detail(data) {
      this.$store.commit("transaksi/SET_DETAIL_BILLING", data.item);
      this.$router.push({ name: "billing-detail" });
    },
  },
};
</script>

<style></style>
